import Loading from '@src/icons/loading'
import classNames from 'classnames'
import React, { ReactNode } from 'react'

export interface LoadingContainerProps {
  loading: boolean
  className?: string
  children?: ReactNode
}

function LoadingContainer({ loading = false, className, children }: LoadingContainerProps) {
  if (loading === false) {
    return <>{children}</>
  }
  return (
    <div className={classNames('flex justify-center w-full my-32 md:min-h-[400px]', className)}>
      <Loading />
    </div>
  )
}

export default LoadingContainer
