import { useRouter } from 'next/router'
import { ReactNode, useEffect, useMemo, useState } from 'react'

import { authApi, useUserAuth } from '@src/api/auth'
import { Event } from '@src/api/types'
import { formatDate } from '@src/lib/utils'
import { PurchaseModal } from './purchase-modal'
import { LoginModal } from './login-modal'
import classNames from 'classnames'
import dynamic from 'next/dynamic'
import WatchNowIcon from '@src/icons/watch-now'
import { encodeEpisodeIndex, isVideoDateLive } from '@src/utils/vod-series'
import { track } from '@lib/whoami'
// This button was causing a hydration error, so we had to use dynamic import
const Button = dynamic(() => import('./ui/button'), { ssr: false })
export interface PurchaseButtonProps {
  event: Event
  className?: string
  isPreview?: boolean | undefined
  disablePromoCode?: boolean
  children?: ReactNode
  renderAsLink?: boolean
  playLatestEpisode?: boolean
}

function getRedirectUrlByEventType(event: Event, episodeIndex: number) {
  switch (event?.type) {
    case 'barstool_tv_vod':
      return `/vod/${event?.id}/watch`
    case 'barstool_tv_ppv':
      return `/ppv/${event?.id}/watch`
    case 'barstool_tv_vod_series':
      return `/vod-series/${event?.id}/watch${
        typeof episodeIndex === 'number' ? '/' + encodeEpisodeIndex(episodeIndex) : ''
      }`
    default:
      return '/'
  }
}

export function PurchaseButton({
  event,
  className,
  isPreview,
  disablePromoCode = false,
  playLatestEpisode = false,
  renderAsLink = false,
  children
}: PurchaseButtonProps) {
  const router = useRouter()
  const user = useUserAuth()
  const { promo } = router.query
  const [promoWindowOpen, setPromoWindowOpen] = useState<boolean>(false)
  const [userModalOpen, setUserModalOpen] = useState(false)
  const [purchaseModalOpen, setPurchaseModalOpen] = useState(false)
  const [purchaseFromLogin, setPurchaseFromLogin] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  const eventId = event.id
  const eventDate = event.date
  const hasAccess = authApi.hasEventAccess(eventId)

  useEffect(() => {
    if (!disablePromoCode && promo && !promoWindowOpen) {
      setPromoWindowOpen(true)
      handleEventButtonClick()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [promo])

  const availableVideos = useMemo(() => {
    return event?.type === 'barstool_tv_vod_series' && event?.video_series
      ? event?.video_series?.filter((v) => isVideoDateLive(v.date)).length
      : false
  }, [event])

  function handleEventButtonClick() {
    if (isPreview) return

    if (!promo)
      if (!user) {
        setUserModalOpen(true)
      } else {
        if (hasAccess) {
          setIsLoading(true)

          router.push(
            getRedirectUrlByEventType(
              event,
              availableVideos && playLatestEpisode ? availableVideos : 0
            )
          )
        } else {
          setPurchaseModalOpen(true)
          track('ContentPurchaseStarted', {
            content: {
              barstoolEventID: eventId
            },
            cart: {
              totalPrice: event.price * 100, // in cents
              isPaymentMethodEntered: false,
              currencyIsoCode: 'USD'
            }
          })
        }
      }
  }

  const generateButtonText = () => {
    switch (event?.type) {
      case 'barstool_tv_vod':
      case 'barstool_tv_vod_series':
        if (!hasAccess) {
          return children
        }

        let buttonCopy =
          availableVideos && availableVideos > 0 && playLatestEpisode ? `Watch Series` : 'Watch Now'

        return (
          <div className='flex items-center justify-center'>
            <WatchNowIcon className='mb-0 mr-2' />
            {buttonCopy}
          </div>
        )
      case 'barstool_tv_ppv':
        return hasAccess
          ? event?.status == 'live'
            ? 'Watch Live Now'
            : event?.status === 'on_demand'
            ? 'Watch Now'
            : event?.status === 'ended'
            ? `Replay will be available shortly`
            : `Watch Live On ${formatDate(eventDate, 'L/d @ h:mma', {
                zone: 'America/New_York'
              })} ET`
          : children
      default:
        return children
    }
  }

  return (
    <>
      {renderAsLink ? (
        <div onClick={handleEventButtonClick}>{children}</div>
      ) : (
        <Button
          primary
          onClick={handleEventButtonClick}
          className={classNames(
            'rounded border-2 border-[#C61010] py-[10px] hover:border-[#A70606] text-[13.5px] md:text-[13.5px]',
            className
          )}
          loading={isLoading}
        >
          {generateButtonText()}
        </Button>
      )}
      {purchaseModalOpen && (
        <PurchaseModal
          fromLogin={purchaseFromLogin}
          event={event as Event}
          isOpen={purchaseModalOpen}
          setIsOpen={setPurchaseModalOpen}
          promoCode={promo}
        />
      )}
      <LoginModal
        event={event}
        isOpen={userModalOpen}
        setIsOpen={setUserModalOpen}
        successMessage='Success! Redirecting to event purchase.'
        onSuccess={() => {
          setPurchaseFromLogin(true)
          setUserModalOpen(false)
          setPurchaseModalOpen(true)
        }}
      />
    </>
  )
}
