import kebabCase from 'lodash/kebabCase'
import { formatDate } from '@src/lib/utils'
import { DateTime } from 'luxon'
import { getDateTimeInterval } from '@src/lib/utils'
import { VODSeriesSource } from '@src/api/types'

export function mapEventVideoToCarouselVideo(videos: VODSeriesSource[]) {
  return videos.map((video, index) => ({
    id: video.id,
    title: video.name,
    index,
    thumbnail: video.thumbnail,
    label: video.name,
    url: video.on_demand?.src,
    date: video.date,
    free_url: video.free_url ?? null,
    free_at: video.free_at ?? null,
    slug: kebabCase(video.name)
  }))
}

export function mapPlaylistToCarouselVideo(videos: any[]) {
  return videos.map((video) => ({
    id: video.id,
    title: video?.title,
    thumbnail: video?.thumbnail?.desktop,
    label: video?.name,
    url: video?.url,
    date: video.date,
    slug: kebabCase(video.title)
  }))
}

export function formatVodSeriesResponse(videos: VODSeriesSource[] | undefined) {
  if (!videos) return []
  return videos?.filter((v) => isVideoDateLive(v.date))
}

export function getLatestEpisodeIndex(videos: VODSeriesSource[] | undefined) {
  const liveVideos = videos?.filter((v) => isVideoDateLive(v.date))
  return liveVideos?.length ? liveVideos.length - 1 : 0
}

export function encodeEpisodeIndex(index: number): string {
  const bufferObj = Buffer.from(String(index), 'utf8')
  const encodedIndex = bufferObj.toString('base64')

  return encodedIndex
}

export function decodeEpisodeIndex(encodedIndex: string): number {
  const bufferObj = Buffer.from(encodedIndex as string, 'base64')
  return Number(bufferObj.toString('utf8'))
}

export function isVideoDateLive(date: string): boolean {
  const now = new Date()
  const videoDate = new Date(date)
  return videoDate.getTime() < now.getTime()
}

export function getEpisodeDateSubtitle(date: string): string {
  const isLive = isVideoDateLive(date)
  if (isLive) {
    const duration = getDateTimeInterval(DateTime.fromISO(date))

    let durationFriendlyName = 0
    let durationLabel = 'days'

    if (typeof duration.days === 'number' && Math.abs(duration.days) > 0) {
      if (Math.abs(duration.days) >= 365) {
        durationFriendlyName = Math.round(Math.abs(duration.days) / 365)
        durationLabel = durationFriendlyName === 1 ? 'year' : 'years'
      } else {
        durationFriendlyName = Math.abs(duration.days)
        durationLabel = durationFriendlyName === 1 ? 'day' : 'days'
      }
    } else if (typeof duration.hours === 'number' && Math.abs(duration.hours) > 0) {
      durationFriendlyName = Math.abs(duration.hours)
      durationLabel = durationFriendlyName === 1 ? 'hour' : 'hours'
    } else if (typeof duration.minutes === 'number' && Math.abs(duration.minutes) > 0) {
      durationFriendlyName = Math.abs(duration.minutes)
      durationLabel = durationFriendlyName === 1 ? 'minute' : 'minutes'
    }
    return `${durationFriendlyName} ${durationLabel} ago`
  }
  return `Premieres on ${formatDate(date, 'MM/d')}`
}
