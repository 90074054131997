import { unionApiHost } from '@src/lib/env'
import { Service } from './common'

class AnalyticsApi extends Service<any> {
  constructor() {
    super('/analytics', unionApiHost)
  }

  async updateKlaviyo(data: any) {
    return await this.put(`${this.basePath}/klaviyo`, data)
  }
}

export const analyticsApi = new AnalyticsApi()
