import MuxPlayer, { MuxPlayerProps } from '@mux/mux-player-react'
import { muxEnvKey } from '@src/lib/env'
import { VideoSource } from '@src/api/types'
import { forwardRef, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import classNames from 'classnames'
import MuxPlayerElement from '@mux/mux-player/.'
import Loading from '@src/icons/loading'
import { track } from '@lib/whoami'
export interface VideoPlayerProps extends MuxPlayerProps {
  id: string | number
  name: string
  className?: string
  containerClassName?: string
  status: 'on_demand' | 'live' | 'ppv'
  thumbnail?: string
  source: VideoSource
  videoId?: string | number
  dvr?: boolean
  brand?: { name: string; id: number | string }
  autoPlay?: boolean
  shouldTrackWatchHistory?: boolean
  includeVideoName?: boolean
  onVideoEnded?: (videoId: string) => void
  loading?: boolean
  onPlayerReady?: () => void
}

enum VideoPlayerState {
  idle = 'idle',
  started = 'started'
}

function getStreamType(status: string, dvr: boolean) {
  if (status === 'live') {
    return 'live'
  } else if (dvr) {
    return 'live:dvr'
  } else {
    return 'on-demand'
  }
}

export const Player = forwardRef(
  (
    {
      status,
      source,
      id,
      className,
      containerClassName,
      autoPlay,
      thumbnail,
      loop,
      muted,
      style,
      videoId,
      brand,
      name,
      includeVideoName = false,
      dvr = false,
      metadata = {},
      onVideoEnded,
      loading = false,
      onPlayerReady
    }: VideoPlayerProps,
    playerRef: any
  ) => {
    const [isPaused, setIsPaused] = useState<boolean>(false)
    const intervalRef = useRef<NodeJS.Timeout | null>(null)
    const [currentTime, setCurrentTime] = useState<number>(0)
    const live = status === 'live'
    const [playerState, setPlayerState] = useState<VideoPlayerState>(
      live ? VideoPlayerState.started : VideoPlayerState.idle
    )
    const playerNotStarted = playerState !== VideoPlayerState.started
    const isSignedUrl = source.src.match('token=')

    const playerProps = useMemo(() => {
      const url = new URL(source.src)
      if (url.hostname === 'stream.mux.com') {
        const playbackId = url.pathname.slice(1).split('.')[0]
        const props: MuxPlayerProps = { playbackId }
        if (isSignedUrl) {
          const token = url.searchParams.get('token') ?? undefined
          props.tokens = {
            playback: token
          }
        }
        return props
      } else {
        return { src: url.href }
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [source.src])

    function setStart() {
      if (dvr && playerRef && playerRef.current) {
        playerRef.current.currentTime = currentTime
      }
    }

    const handleOnPlay = useCallback(() => {
      setIsPaused(false)
      if (playerNotStarted) {
        setPlayerState(VideoPlayerState.started)
      }
    }, [playerNotStarted])

    function handleOnPause() {
      setIsPaused(true)
      if (intervalRef.current) {
        clearInterval(intervalRef.current)
        intervalRef.current = null
      }
    }

    function handleOnEnded() {
      typeof onVideoEnded === 'function' && onVideoEnded(String(videoId))
      track('ContentCompleted', {
        content: {
          type: 'Video',
          src: source.src,
          title: name
        }
      })
    }

    useEffect(() => {
      setPlayerState(live ? VideoPlayerState.started : VideoPlayerState.idle)
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id])

    useEffect(() => {
      if (playerRef.current && typeof onPlayerReady === 'function') {
        onPlayerReady()
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [playerRef])

    return (
      <div className={classNames('w-full h-full overflow-hidden relative', containerClassName)}>
        <MuxPlayer
          ref={playerRef}
          className={classNames(
            'bstv-player',
            status === 'ppv' ? 'w-full' : 'w-[100vw]',
            {
              '[--bottom-controls:none] [--seek-backward-button:none] [--seek-forward-button:none]':
                playerNotStarted,
              '[--controls:none]': loading
            },
            className
          )}
          key={id}
          startTime={dvr ? 0 : undefined}
          autoPlay={autoPlay ?? live ? true : false}
          streamType={getStreamType(status, dvr)}
          envKey={muxEnvKey}
          defaultHiddenCaptions={true}
          onPlay={() => {
            handleOnPlay()
            track('ContentStarted', {
              content: {
                type: 'Video',
                src: source.src,
                title: name
              }
            })
          }}
          onPause={handleOnPause}
          onEnded={handleOnEnded}
          poster={thumbnail}
          onLoadedData={setStart}
          loop={loop}
          muted={muted}
          style={style}
          metadata={{
            video_id: id,
            video_title: name,
            video_variant_id: String(videoId),
            video_series: brand?.name,
            ...metadata
          }}
          playsInline
          {...playerProps}
        />
        {loading && (
          <div className='absolute top-0 left-0 flex items-center justify-center w-full h-full'>
            <Loading />
          </div>
        )}
        {includeVideoName && isPaused && (
          <div className='absolute left-0 z-10 flex items-center w-full pl-3 text-white filter bottom-12 h-11'>
            {name}
          </div>
        )}
      </div>
    )
  }
)

Player.displayName = 'Player'

function VideoPlayer(props: VideoPlayerProps) {
  const ref = useRef<MuxPlayerElement>(null)

  return <Player ref={ref} {...props} />
}

export default VideoPlayer
