import { apiHost } from '@src/lib/env'
import storage from '@src/lib/storage'
import { Service, SWRConfiguration, useGet } from './common'
import { ListParams } from './types'
import { ApiError } from './utils'
import { Purchase } from './types'

class PurchasesApi extends Service<Purchase> {
  constructor() {
    super('purchases', apiHost)
  }

  async purchaseEvent(id: string, { zip }: { zip: string | null }): Promise<Purchase> {
    // combine idempotencyKey with eventId to ensure uniqueness across purchases of different events
    const idempotencyKey = storage.getIdempotencyKey(id)
    try {
      const purchase = await this.post(`/purchases`, {
        event_id: id,
        zip,
        idempotency_key: idempotencyKey
      })

      // remove idempotencyKey from storage if purchase was successful. By removing idempotencyKey from storage, a new one will be
      // generated on the fly next time a purchase is made
      storage.setIdempotencyKey(id, null)
      return purchase as Purchase
    } catch (e) {
      // do nothing for error statuses >= 500 so that same key is reused on any subsequent requests
      if (e instanceof ApiError && e.status < 500) {
        // remove idempotencyKey from storage if error code < 500, purchases with 4xx error codes should be
        // retried with different idempotency key. By removing idempotencyKey from storage, a new one will be
        // generated on the fly next time a purchase is made
        storage.setIdempotencyKey(id, null)
      }
      throw e
    }
  }

  async purchasePromoEvent(
    id: string,
    { promo_code }: { promo_code: string | undefined }
  ): Promise<Purchase> {
    try {
      const purchase = await this.post(`/purchases`, {
        event_id: id,
        promo_code
      })

      return purchase as Purchase
    } catch (e) {
      throw e
    }
  }
}

export const purchasesApi = new PurchasesApi()

export function usePurchases(params?: ListParams<Purchase>, config?: SWRConfiguration) {
  const { data, error, mutate } = useGet<any>(purchasesApi, `/purchases`, params, config)

  return {
    data,
    error,
    isLoading: !(error || data),
    mutate
  }
}
