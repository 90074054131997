import { withLogger } from '@barstoolsports/web-sdk'
import { analyticsApi } from '@src/api/analytics'
import { Purchase, User, Event } from '@src/api/types'

const logger = withLogger('Analytics')

const KLAVIYO_ID = process.env.NEXT_PUBLIC_KLAVIYO_LIST_ID

class Analytics {
  api = analyticsApi

  async updateKlaviyo({ profile, delay_seconds }: any) {
    // test environment, we don't want to attempt to update klaviyo with no KLAVIYO_LIST_ID
    if (!KLAVIYO_ID) {
      return
    }
    const data = {
      delay_seconds,
      add_to_lists: [KLAVIYO_ID],
      profile_data: profile
    }
    try {
      return await this.api.updateKlaviyo(data)
    } catch (err) {
      logger.error(err)
    }
  }

  async klaviyoPrePurchase({ user, event }: { user: User; event: Event }) {
    const profile = {
      email: user.email,
      BSTV_Status: `${event?.series?.abbreviation}${event?.series?.episode}_Lead_But_No_Purchase`,
      [`${event?.series?.abbreviation}${event?.series?.episode}_Purchase`]: 'FALSE'
    }
    return await this.updateKlaviyo({ profile })
  }

  async klaviyoPostPurchase({
    user,
    event,
    purchase
  }: {
    user: User
    event: Event
    purchase: Purchase
  }) {
    const profile = {
      email: user.email,
      [`${event?.series?.abbreviation}_Status`]: `${event?.series?.abbreviation}${event?.series?.episode}_Purchase`,
      [`${event?.series?.abbreviation}_Most_Recent_Purchase_Date`]: `${purchase.created_at}`,
      [`${event?.series?.abbreviation}${event?.series?.episode}_Purchase`]: 'TRUE',
      membership_billing_interval: 'once',
      membership_billing_price: `${purchase.price}`,
      membership_plan_id: 'member_bstv_single_stripe',
      membership_provider: 'stripe',
      membership_start_date: `${purchase.created_at}`,
      membership_tier: 'member_bstv'
    }
    return await this.updateKlaviyo({ profile, delay_seconds: 60 })
  }
}

const analytics = new Analytics()

export default analytics
