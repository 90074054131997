import classNames from 'classnames'
export default function WatchNowIcon({ className }: { className?: string }) {
  return (
    <svg
      width='16'
      height='16'
      viewBox='0 0 16 16'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      className={classNames('inline-flex items-center', className)}
    >
      <path
        d='M7.99969 0C10.2084 0 12.2091 0.895625 13.6566 2.34313C15.1041 3.79063 16 5.79094 16 8C16 10.2087 15.1041 12.2091 13.6566 13.6566C12.2091 15.1041 10.2084 16 7.99969 16C5.79094 16 3.79063 15.1041 2.34313 13.6566C0.895625 12.2091 0 10.2087 0 8C0 5.79156 0.895625 3.79063 2.34313 2.34313C3.79063 0.895625 5.79094 0 7.99969 0ZM10.4184 8.43156C10.8481 8.15406 10.8466 7.845 10.4184 7.59937L6.9725 5.20031C6.62281 4.98031 6.25719 5.10969 6.26687 5.56656L6.28062 10.3938C6.31063 10.8894 6.59375 11.0253 7.01094 10.7959L10.4184 8.43156ZM12.8981 3.10156C11.645 1.84844 9.9125 1.07281 7.99969 1.07281C6.08687 1.07281 4.355 1.84844 3.10156 3.10156C1.84844 4.355 1.07281 6.08719 1.07281 8C1.07281 9.91313 1.84844 11.645 3.10156 12.8981C4.355 14.1516 6.08687 14.9272 7.99969 14.9272C9.9125 14.9272 11.645 14.1516 12.8981 12.8981C14.1516 11.645 14.9272 9.91313 14.9272 8C14.9272 6.08687 14.1516 4.355 12.8981 3.10156Z'
        fill='white'
      />
    </svg>
  )
}
