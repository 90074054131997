import { Dispatch, SetStateAction, useEffect, useState } from 'react'
import { useRouter } from 'next/router'
import { Modal } from './ui/modal'
import { ApiError } from '@src/api/utils'
import { formatCreditCardExp, formatDate } from '@src/lib/utils'
import {
  CardElement,
  useStripe,
  useElements,
  PaymentRequestButtonElement
} from '@stripe/react-stripe-js'
import Button from './ui/button'
import { useCurrentUser, userApi, useStripeCustomer } from '@src/api/user'
import { Event } from '@src/api/types'
import { PaymentRequest } from '@stripe/stripe-js'
import { purchasesApi, usePurchases } from '@src/api/purchases'
import { FormNotification } from './ui/form-notification'
import LoadingContainer from './loading-container'
import { authApi } from '@src/api/auth'
import { PurchaseButton } from './ppv-purchase-button'
import { isBefore, parseISO, sub } from 'date-fns'
import { Purchase } from '@src/api/types/purchases'
import { sendPurchase } from '@src/lib/analytics'
import analytics from '@src/lib/pixels/analytics'
import { track } from '@lib/whoami'

export function PurchaseModal({
  fromLogin,
  event,
  isOpen,
  setIsOpen,
  promoCode: _promoCode
}: {
  fromLogin?: boolean
  event: Event
  isOpen: boolean
  setIsOpen: Dispatch<SetStateAction<boolean>>
  promoCode: string | string[] | undefined
}) {
  const router = useRouter()
  const stripe = useStripe()
  const elements = useElements()
  const { data: user } = useCurrentUser()
  const { data: stripeCustomer = {}, isLoading } = useStripeCustomer()
  const [usePromoCode, setUsePromoCode] = useState<boolean>(!!_promoCode)
  const [promoCode, setPromoCode] = useState<string | undefined>(_promoCode as string | undefined)
  const { data: purchases = [] } = usePurchases()
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false)
  const [changePaymentMethod, setChangePaymentMethod] = useState<boolean>(false)
  const [paymentRequest, setPaymentRequest] = useState<PaymentRequest | undefined>()
  const [modalBody, setModalBody] = useState<'purchase' | 'processing' | 'confirmation'>('purchase')
  const [error, setError] = useState<string | undefined>()

  const hasAccess = authApi.hasEventAccess(event.id)
  const price = {
    dollars: event?.price?.toString().split('.')[0] ?? 0,
    cents: event?.price?.toFixed(2).toString().split('.')[1] ?? 0
  }

  const { default_source: defaultSource } = stripeCustomer

  useEffect(() => {
    if (hasAccess) setModalBody('confirmation')
  }, [hasAccess])

  useEffect(() => {
    if (isOpen && user && !hasAccess) {
      analytics.klaviyoPrePurchase({ user, event })
    }
  }, [isOpen, user, hasAccess, event])

  useEffect(() => {
    const pr = stripe?.paymentRequest({
      country: 'US',
      currency: 'usd',
      requestPayerName: true,
      total: {
        label: event.name,
        amount: Math.round(event.price * 100)
      }
    })
    // Check the availability of the Payment Request API.
    pr?.canMakePayment().then((success) => {
      if (success) setPaymentRequest(pr)
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stripe])

  useEffect(() => {
    paymentRequest?.on('token', async (event) => {
      try {
        setIsSubmitting(true)
        const { default_source }: any = await userApi.addPaymentMethod(event.token.id)
        await handleCreatePurchase(default_source.address_zip)
        event.complete('success')
      } catch (err) {
        event.complete('fail')
      }
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paymentRequest])

  async function handlePromoPurchase() {
    try {
      setModalBody('processing')
      const purchase = await purchasesApi.purchasePromoEvent(event.id, {
        promo_code: promoCode
      })
      if (purchase) setModalBody('confirmation')
    } catch (e) {
      if (e instanceof ApiError)
        setError(e?.message === 'Not Found' ? 'Invalid promo code. Please try again.' : e?.message)
      else if (e instanceof Error) setError(e?.message)
      else setError('Invalid promo code. Please try again.')
      setModalBody('purchase')
    } finally {
      setIsSubmitting(false)
    }
  }

  async function handleCreatePurchase(zip: string) {
    try {
      setModalBody('processing')
      const purchase = await purchasesApi.purchaseEvent(event.id, {
        zip
      })
      await analytics.klaviyoPostPurchase({ user, event, purchase })
      sendPurchase(purchase, event)
      track('ContentPurchaseCompleted', {
        content: {
          barstoolEventID: event.id
        },
        cart: {
          barstoolPurchaseID: purchase.id,
          totalPrice: event.price * 100, // in cents
          currencyIsoCode: 'USD'
        }
      })
      setModalBody('confirmation')
    } catch (e) {
      if (e instanceof ApiError) setError(e?.message)
      else if (e instanceof Error) setError(e?.message)
      else setError('Error with payment method. Please try again.')
      setModalBody('purchase')
    } finally {
      setIsSubmitting(false)
    }
  }

  async function handleCreateOrUpdatePaymentSource() {
    // dont allow submit if stripe is not loaded
    if (!stripe || !elements) {
      setError('Stripe not initialized. Please try again in a moment.')
      return
    }

    const customerCard = elements.getElement(CardElement)

    if (!customerCard) throw new Error('Invalid payment method.')

    const { token, error } = await stripe.createToken(customerCard)

    if (error) {
      throw error
    } else {
      const { default_source }: any = await userApi.addPaymentMethod(token.id)
      return default_source
    }
  }

  async function handleSubmit() {
    if (!stripe) {
      setError('Stripe error. Please try again later')
      return
    }

    setIsSubmitting(true)

    try {
      let userDefaultSource = defaultSource

      if (!defaultSource || changePaymentMethod) {
        userDefaultSource = await handleCreateOrUpdatePaymentSource()
      }
      await handleCreatePurchase(userDefaultSource.address_zip)
    } catch (e) {
      if (e instanceof ApiError) setError(e?.message)
      else if (e instanceof Error) setError(e?.message)
      else setError('Error with payment method. Please try again.')
      setIsSubmitting(false)
      setModalBody('purchase')
    }
  }

  const currentDate = new Date()

  const hasRecentlyPurchased = purchases.some((purchase: Purchase) => {
    const createdAt = parseISO(purchase.created_at)
    return purchase.event.id === event.id && isBefore(createdAt, sub(currentDate, { hours: 1 }))
  })

  return (
    <Modal
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      onClose={() => {
        router.reload()
        setChangePaymentMethod(false)
      }}
      title={modalBody === 'purchase' && `You're one click away from ${event?.name}.`}
      hideCloseIcon={modalBody === 'processing' || isLoading}
      classname='animate-fadeInScale'
    >
      <LoadingContainer loading={isLoading} className='pt-20 my-0'>
        <>
          {hasAccess || modalBody === 'confirmation' ? (
            <div className='flex flex-col items-center text-sm'>
              <div className='flex flex-col items-center w-full mt-[32px]'>
                <h1 className='mb-8 font-sans text-[32px] font-bold text-white'>
                  {hasRecentlyPurchased
                    ? "You've already purchased this title."
                    : 'Thank You For Your Purchase.'}
                </h1>
                <PurchaseButton
                  playLatestEpisode={hasRecentlyPurchased}
                  event={event}
                  disablePromoCode
                />
              </div>
            </div>
          ) : modalBody === 'processing' ? (
            <div className='flex flex-col items-center mt-16'>
              <img
                src='/static/images/stool-and-stars-white.svg'
                alt='stool-and-stars-success'
                className='mb-6 w-28 h-28'
              />
              <p className='text-sm text-center'>
                Purchase is processing...
                <br />
                Please do not refresh the page.
              </p>
            </div>
          ) : (
            <>
              <div className='py-[15px] px-[22px] mb-10 text-white rounded-lg gradient-6'>
                <h2 className='font-sans text-[22px] font-extralight leading-[26px] mb-5'>
                  One Time Purchase of {event?.name}
                </h2>
                <div className='flex items-start'>
                  <div className='bg-[#404040] shrink-0 rounded h-[30px] w-[30px] mr-5 relative overflow-visible'>
                    <img
                      className='absolute top-0 left-2'
                      src='/static/images/checkbox-check.png'
                      alt='checkbox'
                    />
                  </div>
                  <div>
                    <h2 className='text-2xl tracking-[.7px] font-normal'>PAY-PER-VIEW PURCHASE</h2>
                    <div className='relative inline-flex items-start'>
                      <h1 className='text-[100px] leading-none'>${price.dollars}</h1>
                      <div className='relative ml-[6px]'>
                        <h3 className='text-[38px] font-serif leading-none mt-2'>.{price.cents}</h3>
                        {event?.type === 'barstool_tv_vod_series' && (
                          <h4 className='font-sans text-sm text-[#DEDEDE] mb-[10px] absolute w-32 left-1'>
                            One Time Payment + Applicable Taxes
                          </h4>
                        )}
                      </div>
                      {usePromoCode && (
                        <div className='absolute w-full border-2 border-[#C61010] top-1/3' />
                      )}
                    </div>
                    {event?.type !== 'barstool_tv_vod_series' && (
                      <h4 className='font-sans text-xs text-[#DEDEDE] mb-[10px]'>
                        Gain access to {event.name} on {formatDate(event.date, 'LLLL d, t ZZZZ')}
                      </h4>
                    )}
                  </div>
                </div>
              </div>
              {fromLogin && <h3 className='mb-1 text-sm font-medium uppercase'>Step 3 of 3</h3>}
              <h3 className='mb-6 text-sm text-white'>
                {usePromoCode ? 'Enter your promo code.' : 'Provide your payment details.'}
              </h3>
              <div className='mb-6'>
                {usePromoCode ? (
                  <div
                    className={
                      'w-full text-white flex border-2 border-white bg-transparent px-[25px] py-[15px]'
                    }
                  >
                    <input
                      value={promoCode}
                      onChange={(event) => setPromoCode(event?.target?.value)}
                      placeholder={'Promo Code'}
                      className={
                        'w-full bg-transparent p-0 placeholder:text-[#7B7B7B] text-sm border-none leading-none focus:ring-transparent focus:outline-none focus:border-white'
                      }
                    />
                  </div>
                ) : (
                  <>
                    {paymentRequest && (
                      <div className='mb-6'>
                        <h2 className='font-sans text-[#B3B3B3] mb-4 text-base'>Pay with</h2>
                        <PaymentRequestButtonElement
                          options={{
                            paymentRequest,
                            style: { paymentRequestButton: { theme: 'light' } }
                          }}
                        />
                        <div className='flex items-center mt-6'>
                          <div className='border-b border-[#404040] flex-1' />
                          <h2 className='font-sans text-[#B3B3B3] mx-10 text-base'>Or</h2>
                          <div className='border-b border-[#404040] flex-1' />
                        </div>
                      </div>
                    )}
                    {defaultSource && !changePaymentMethod ? (
                      <div className='flex items-center justify-between px-[25px] py-[15px] text-sm text-white bg-transparent border-2 border-white leading-4'>
                        <div>
                          {defaultSource.brand} ending in {defaultSource.last4}
                        </div>
                        <div className='flex items-center basis-auto'>
                          <div>
                            {formatCreditCardExp(defaultSource.exp_month, defaultSource.exp_year)}
                          </div>
                          <button
                            onClick={() => setChangePaymentMethod(true)}
                            className='text-[#79C0FF] font-medium ml-4'
                          >
                            Change
                          </button>
                        </div>
                      </div>
                    ) : (
                      <div className='px-[25px] py-[15px] bg-transparent border-2 border-white'>
                        <CardElement
                          options={{
                            style: {
                              base: {
                                fontSize: '14px',
                                color: '#fff',
                                '::placeholder': {
                                  color: '#7B7B7B'
                                }
                              },
                              invalid: {
                                color: '#9e2146'
                              }
                            }
                          }}
                        />
                      </div>
                    )}
                  </>
                )}
              </div>
              {error && <FormNotification className='mb-6' error message={error} />}
              <button
                className='text-sm font-normal text-white underline'
                onClick={() => {
                  setUsePromoCode((prev) => !prev)
                  setError(undefined)
                }}
              >
                {usePromoCode ? "I don't have a promo code." : 'Have a promo code?'}
              </button>
              <p className='text-[#B3B3B3] tracking-[.4px] my-3 text-xs'>
                By clicking &quot;Submit&quot;, you are confirming your agreement to the applicable{' '}
                <a
                  className='text-white underline'
                  href='https://www.barstoolsports.com/terms-of-use'
                  target='_blank'
                  rel='noreferrer'
                >
                  Terms
                </a>
                . All purchases are final and no refunds will be issued
              </p>
              <Button
                primary
                onClick={() => {
                  if (usePromoCode && promoCode && promoCode?.length > 0) {
                    handlePromoPurchase()
                  } else {
                    handleSubmit()
                  }
                }}
                loading={isSubmitting}
                // disabled={pristine} @TODO: Disable if no payment method
              >
                Submit
              </Button>
            </>
          )}
        </>
      </LoadingContainer>
    </Modal>
  )
}
